import React from "react";

const MAX_IMAGE_RETRY_COUNT = 5;

export default class DumbImageView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };

    this.imageRetryCount = 0;
    this.imageRetryTimeoutId = undefined;
    this.imageRetryTimeoutMs = 1000;

    this.image = new Image();
    this.image.onload = () => {
      this.props.onImageLoaded && this.props.onImageLoaded(this.image);
      this.setState({isLoading: false});
    };

    this.image.onerror = () => {
      if (this.imageRetryCount < MAX_IMAGE_RETRY_COUNT) {
        this.imageRetryTimeoutId = setTimeout(() => {
          const imageUrl = new URL(this.image.src);
          //imageUrl.searchParams.set("r", Math.round(100000 * Math.random()));
          this.image.src = imageUrl.toString();
          this.imageRetryCount++;
        }, this.imageRetryTimeoutMs);
      } else {
        this.props.onImageError && this.props.onImageError();
      }
    };

    this.load = this.load.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (this.props.image) {
      this.load();
    }
  }

  componentWillUnmount() {
    this.image.onload = null;
    this.image.onerror = null;
    clearTimeout(this.imageRetryTimeoutId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.load();
    }
  }

  load() {
    this.setState({
      isLoading: true,
    }, () => {
      this.image.src = this.props.image;
      this.imageRetryCount = 0;
      clearTimeout(this.imageRetryTimeoutId);
    });
  }

  handleClick(e) {
    this.props.onClick && this.props.onClick(e);
  }

  render() {
    const isImageDisplayed = this.props.image && !this.state.isLoading;
    const styles = {};

    if (isImageDisplayed) {
      styles.backgroundImage = `url(${this.props.image})`;
    }

    return <img
      className={this.props.className || ""} 
      onClick={this.handleClick}
      hidden={!isImageDisplayed}
      src={this.props.image} alt='' />;
  }
}
