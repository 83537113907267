import CreativeConfig from "../CreativeConfig";
import Creative from "../Creative";
import {handlersNames} from "../handlers";

export const apiWatermarkStep = {
  type: "api_watermark",
  setAsFile: "raw_watermark",
};

export const photolabWatermarkStep = {
  type: "template",
  id: 6826,
  setAsFile: "raw_watermark",
};

export const frontendWatermarkStep = {
  type: "watermark",
  setAsFile: "raw_watermark",
  watermark: {
    url: "/assets/images/watermarks/default_4.png",
    position: "bottom-right",
    x: 3, // %
    y: 2, // %
    percentage: 30,
  },
}

export function combo(group, comboId, steps) {
  return new CreativeConfig(group, comboId, handlersNames.COMBO)
    .setExtra(Creative.EXTRA_COMBO_STEPS, steps);
}

export function ingroupCollage(group) {
  return new CreativeConfig(group, "collage", handlersNames.INGROUP_COLLAGE)
    .setExtra(Creative.EXTRA_COLLAGE_SHOW_COMPLEX, true)
    .setExtra(Creative.EXTRA_IS_COLLAGE, true);
}

export function groupedCollage(group) {
  return new CreativeConfig(group, "default", handlersNames.GROUPED_COLLAGE)
    .setExtra(Creative.EXTRA_COLLAGE_SHOW_COMPLEX, true)
    .setExtra(Creative.EXTRA_IS_COLLAGE, true);
}

export function whenSplit(splits = []) {
  return splits.indexOf(window.clientConfig.splitGroupId) > -1;
}