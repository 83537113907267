import * as api from "../../utils/api";
import PhotolabTaskBuilder from "../PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../PhotolabTaskImageUrl";
import Creative from "../Creative";
import {CreativeTimeoutError, defaultHandlerCatch, defaultHandlerResolver, promisifyImage, waitTaskHelper} from "./helpers";
import {photolabTask} from "../api";
import {createMd5Token} from "../../utils/text";
import {assetUrl} from "../../utils/etc";
import {transformToDownloadUrl} from "../../utils/creative";
import {hitEvent, hits} from "../../utils/log";

const tasksCache = {};

function getResultUrlFromTask(task) {
  if (task.requestId) {
    return task.resultUrl
  } else {
    return task.result.url;
  }
}

function templateStep(processing, creative, stepIndex, stepConfig) {
  if (!stepConfig.id) {
    return Promise.reject("No template ID parameter.");
  }

  const taskConfigBuilder = new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod(stepConfig.id));

  // @ - original photo
  // # - prev step result
  // #N - N step result, todo
  (stepConfig.images || [{src: "#"}]).forEach((image) => {
    const source = image.src || "#";
    let imageUrl;

    if (source === "@") {
      imageUrl = processing.file.url;
    } else if (source === "#") {
      if (stepIndex === 0) {
        imageUrl = processing.file.url;
      } else {
        imageUrl = getResultUrlFromTask(creative.getTask("s" + (stepIndex-1)));
      }
    } else {
      imageUrl = source;
    }

    const altBody = (image.useAltBody && processing.maskFile) ? processing.maskFile.url : "";
    const fileName = altBody.split("/").pop();
    const hash = fileName.substring(0, fileName.lastIndexOf("."));

    taskConfigBuilder.addImage(new PhotolabTaskImageUrl(
      imageUrl + (hash.length ? "?" + hash : ""),
      "",
      0,
      0,
      altBody
    ));
  });

  const taskConfig = taskConfigBuilder.build()
  const taskCacheKey = createMd5Token(taskConfig);

  if (!tasksCache[taskCacheKey]) {
    tasksCache[taskCacheKey] = photolabTask(
      taskConfig,
      stepConfig.getResultTimeout || 1000,
      stepConfig.getResultInterval || 1000,
    )
  }

  creative.setTaskConfig("s" + stepIndex, taskConfig);

  return tasksCache[taskCacheKey].then((taskResult) => {
    creative.setTask("s" + stepIndex, taskResult);

    if (typeof stepConfig.setAsFile === "string" && stepConfig.setAsFile.length > 0) {
      creative.setFile(stepConfig.setAsFile, taskResult.resultUrl);
    }

    return taskResult;
  }).catch((err) => {
    delete tasksCache[taskCacheKey];
    throw err;
  });
}

function apiWatermarkStep(processing, creative, stepIndex, stepConfig) {
  const imageUrl = getResultUrlFromTask(creative.getTask("s" + (stepIndex-1)));

  const watermarkConfig = {
    "content_url": assetUrl("assets/images/watermarks/default_4.png"),
    "position": "bottom-right",
    "x": "3%",
    "y": "2%",
    "percentage": 30,
  };

  return api.createTask("creative_layers", {content_url: imageUrl, layers: [watermarkConfig]})
    .then((taskResult) => waitTaskHelper(creative, "template_store", taskResult, 1000))
    .then((taskResult) => {
      creative.setTask("s" + stepIndex, taskResult);

      if (typeof stepConfig.setAsFile === "string" && stepConfig.setAsFile.length > 0) {
        creative.setFile(stepConfig.setAsFile, taskResult.result.url);
      }

      return taskResult;
    });
}

export function addWatermark(source, watermark, watermarkConfig) {
  const canvas = document.createElement("canvas");
  canvas.width = source.width;
  canvas.height = source.height;

  const canvasCtx = canvas.getContext("2d");
  canvasCtx.drawImage(source, 0, 0);

  const watermarkWidth = Math.round(canvas.width / 100 * watermarkConfig.percentage);
  const watermarkHeight = watermarkWidth * watermark.height / watermark.width;

  const watermarkPosX = canvas.width - (watermarkWidth + Math.round(watermarkConfig.x * (canvas.width / 100)));
  const watermarkPosY = canvas.height - (watermarkHeight + Math.round(watermarkConfig.y * (canvas.height / 100)));

  const watermarkCanvas = document.createElement("canvas");
  watermarkCanvas.width = watermark.width;
  watermarkCanvas.height = watermark.height;

  const watermarkCanvasCtx = watermarkCanvas.getContext("2d");
  watermarkCanvasCtx.drawImage(watermark, 0, 0, watermarkWidth, watermarkHeight);

  canvasCtx.drawImage(watermarkCanvas, watermarkPosX, watermarkPosY);

  return canvas;
}

export function watermarkStep(processing, creative, stepIndex, stepConfig) {
  // if (window.clientConfig.splitGroupId > 1) {
  //   return Promise.resolve();
  // }

  hitEvent(hits.FRONTEND_WATERMARK_STARTED);

  const imageUrl = transformToDownloadUrl(getResultUrlFromTask(creative.getTask("s" + (stepIndex-1))));
  const fileName = imageUrl.substring(imageUrl.lastIndexOf('/')+1);
  const watermarkConfig = stepConfig.watermark;

  return Promise.all([
    promisifyImage(imageUrl, true),
    promisifyImage(watermarkConfig.url, true),
  ])
  .then(([source, watermark]) => {
    const canvas = addWatermark(source, watermark, watermarkConfig);

    return new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg"));
  })
  .then((blob) => api.storeFile(blob, fileName))
  .then((storeResult) => {
    if (storeResult.status === 200) {
      hitEvent(hits.FRONTEND_WATERMARK_PROCESSED);
      creative.setFile(
        stepConfig.setAsFile || "raw_watermark",
        transformToDownloadUrl("https://client-upload-proxy/" + fileName)
      );
    } else {
      throw new Error(storeResult.statusText);
    }
  })
  .catch((error) => {
    hitEvent(hits.FRONTEND_WATERMARK_FAILED);

    throw error;
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export default (processing, creative) => {
  const steps = creative.getExtra(Creative.EXTRA_COMBO_STEPS);

  function waitChain(index) {
    return new Promise((resolve, reject) => {
      const step = steps[index];
      let stepHandler = null;

      switch (step.type || "template") {
        case "template": {
          stepHandler = templateStep(processing, creative, index, step);
          break;
        }
        case "api_watermark": {
          stepHandler = apiWatermarkStep(processing, creative, index, step);
          break;
        }
        case "watermark": {
          stepHandler = watermarkStep(processing, creative, index, step);
          break;
        }
        default: {
          throw new Error(`Unrecognized combo step: '${step.type}'.`);
        }
      }

      return stepHandler
        .then((res) => steps[index + 1] ? waitChain(index + 1) : res)
        .then(resolve)
        .catch(reject);
    });
  }

  const timeoutMs = creative.getExtra(
    Creative.EXTRA_PROCESSING_TIMEOUT,
    window.appConfig.processings.creativeTimeout
  );

  let timeoutPromise = new Promise((resolve, reject) => {
    if (timeoutMs > 0) {
      let timeoutTimer = setTimeout(() => {
        clearTimeout(timeoutTimer);
        reject(new CreativeTimeoutError());
      }, timeoutMs);
    }
  });

  return new Promise((resolve, reject) => {
    // todo остановить waitChain
    Promise.race([
      timeoutPromise,
      waitChain(0),
    ])
    .then(() => {
      creative.markAsProcessed(
        creative.getFile("raw_watermark") || creative.getFile("raw")
      );
    })
    .then(defaultHandlerResolver(creative, resolve))
    .catch(defaultHandlerCatch(creative, reject));
  });
}
