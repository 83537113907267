import Creative from "../photolab/Creative";

export function resolveCreativeImageFile(creative, sharing) {
  if (creative.getExtra(Creative.EXTRA_COLLAGE_SHOW_COMPLEX)) {
    return (sharing && creative.getFile("share_complex"))
      || creative.getFile("complex_watermark") || creative.getFile("complex");
  }

  return (sharing && creative.getFile("share_raw"))
    || creative.getFile("raw_watermark") || creative.getFile("raw");
}

export function transformToDownloadUrl(imageUrl) {
  const appUrl = new URL(window.appConfig.paths.proxy);
  const url = new URL(imageUrl);
  const hostname = url.hostname.toLowerCase();

  if (hostname === "worker-images.ws.pho.to") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/worker-images.ws.pho.to" + url.pathname;
  }

  if (hostname.match(/worker-images-[2-9].ws.pho.to/)) {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/" + hostname + url.pathname;
  }

  if (hostname === "webview-app.ws.pho.to") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download" + url.pathname;
  }

  if (hostname === "pw.test.photo-cdn.net") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/pw.test.photo-cdn.net" + url.pathname;
  }

  if (hostname === "client-upload-proxy") {
    url.protocol = appUrl.protocol;
    url.host = appUrl.host;
    url.pathname = "/download/uploads/npp/creatives" + url.pathname;
  }

  return url.toString();
}
