import React from "react";
import ReactDOM from "react-dom";
import {Route, Switch, withRouter} from "react-router";
import {BrowserRouter} from "react-router-dom";
import routes from "./routes";
import IndexPage from "./pages/IndexPage";
import UploadPage from "./pages/UploadPage";
import MaskEditorPage from "./pages/MaskEditorPage";
import ProcessingPage from "./pages/ProcessingPage";
import ErrorPage from "./pages/ErrorPage";
import ResultPage from "./pages/result/ResultPage";
import SafeAndCoolPage from "./pages/SafeAndCoolPage";
import "./styles/app.scss";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {hitEvent, hits, logEvent, userEvents} from "./utils/log";
import * as webviewUtils from "./utils/webview";
import Loading from "./components/Loading";
import {webviewPreloadAd} from "./utils/webview";
import ErrorBoundary from "./components/ErrorBoundary";
import {debounce} from "./utils/etc";
import {resetNativeAdsCounter} from "./utils/native-ads";

window.onerror = function(message, file, line, col, error) {
  logEvent(userEvents.JS_GLOBAL_ERROR, {
    message,
    file,
    line,
    col,
    error,
    url: window.location.href,
  });
};

class App extends React.Component {

  componentDidMount() {
    resetNativeAdsCounter();

    window.webviewHolders.tabSelected.subscribe((v) => {
      v && this.handleWebviewTabSelected();
    }, true);

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      })
    }

    window.clientConfig.platform.os.toLowerCase() === "ios"
      && document.body.classList.add("ios");

    window.clientConfig.isWebview
      && document.body.classList.add("webview");

    document.body.classList.add(`app-lang--${window.clientConfig.lang}`);

    document.body.addEventListener("mousewheel", (e) => {
      // отключаем скейлинг страницы по ctrl+scroll
      if (e.ctrlKey) {
        e.preventDefault();
      }
    }, {passive: false});

    this.startUpdate();
  }

  handleWebviewTabSelected = () => {
    debounce("App.handleWebviewTabSelected.preloadAds", 300, () => {
      const shouldPreloadAds = window.clientConfig.isWebview
        && window.appConfig.nativeAds.isEnabled
        && !window.clientConfig.isPro;

      if (shouldPreloadAds) {
        hitEvent(hits.NATIVE_ADS_PRELOAD);
        webviewPreloadAd();
      }
    });
  };

  startUpdate = () => {
    let windowHeight = window.innerHeight;
    document.documentElement.style.setProperty("--window-inner-height", windowHeight + "px");

    setInterval(() => {
      if (windowHeight !== window.innerHeight) {
        windowHeight = window.innerHeight;
        document.documentElement.style.setProperty("--window-inner-height", windowHeight + "px");
      }
    }, 100);
  };

  render() {
    return (
      <div>
        <ErrorBoundary>
          <Loading {...this.context.loader} />
          <Switch>
            <Route exact path={routes.INDEX} render={props => <SafeAndCoolPage {...props} />} />
            {/*<Route exact path={routes.UPLOAD} render={props => <UploadPage {...props} />} />*/}
            {/*<Route exact path={routes.MASK_EDITOR} render={props => <MaskEditorPage {...props} />} />*/}
            {/*<Route exact path={routes.PROCESSING} render={props => <ProcessingPage {...props} />} />*/}
            {/*<Route exact path={routes.RESULT} render={props => <ResultPage {...props} />} />*/}
            {/*<Route exact path={routes.ERROR} render={props => <ErrorPage {...props} />} />*/}
            <Route exact path={routes.SAFE_AND_COOL} render={props => <SafeAndCoolPage {...props} />} />
          </Switch>
          <ModalWrapper />
        </ErrorBoundary>
      </div>
    );
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

ReactDOM.render(
  <BrowserRouter>
    <AppContextProvider>
      <AppWithRouter />
    </AppContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
