import i18n from "i18next";
import deStrings from "./languages/de";
import enStrings from "./languages/en";
import esStrings from "./languages/es";
import frStrings from "./languages/fr";
import itStrings from "./languages/it";
import ptBrStrings from "./languages/pt-br";
import ruStrings from "./languages/ru";

const resources = {
  de: deStrings,
  en: enStrings,
  es: esStrings,
  fr: frStrings,
  it: itStrings,
  "pt-rBR": ptBrStrings, // Android
  pt: ptBrStrings, // iOS
  ru: ruStrings,
  // zh: zhStrings, // Android
  // "zh-Hans": zhStrings, // iOS
};

i18n.init({
  resources: resources,
  lng: window.clientConfig.locale,
  fallbackLng: "en",
  debug: window.appConfig.isDebug,
  ns: ["strings"],
  defaultNS: "strings",
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
    formatSeparator: ","
  },
  react: {
    wait: true
  }
}, () => {
  window.axios.defaults.headers.common["X-Locale"] = i18n.language;
});

export default i18n;
