import React from "react";
import PropTypes from "prop-types";
import DumbImageView from "../../components/DumbImageView";
import CreativeErrorView from "./CreativeErrorView";
import CreativeLoaderView from "./CreativeLoaderView";
import Creative from "../../photolab/Creative";
import i18n from "../../i18n";
import {logEvent, userEvents} from "../../utils/log";
import {assetUrl} from "../../utils/etc";
import {resolveCreativeImageFile} from "../../utils/creative";
import clientStorage from "../../utils/client-storage";
import creativeGroups from "../../photolab/config/groups";
import Processing from "../../photolab/Processing";
import {addWatermark} from "../../photolab/handlers/combo.handler";
import {promisifyImage} from "../../photolab/handlers/helpers";
import {frontendWatermarkStep} from "../../photolab/config/helpers";

export default class CreativeView extends React.Component {

  state = {
    isImageLoaded: false,
    watermarkImage: null,
  };

  setWatermarkImage = (image) => {
    const cacheKey = `watermark_image:${image.width}x${image.height}`;

    if (window.appGlobalCache[cacheKey]) {
      this.setState({
        watermarkImage: window.appGlobalCache[cacheKey],
      });

      return;
    }

    const watermarkConfig = frontendWatermarkStep.watermark;

    promisifyImage(watermarkConfig.url, true)
      .then((watermark) => {
        const dummyImage = new Image();
        dummyImage.width = image.width;
        dummyImage.height = image.height;

        const canvas = addWatermark(dummyImage, watermark, watermarkConfig);
        const watermarkImage = canvas.toDataURL();

        this.setState({watermarkImage}, () => {
          window.appGlobalCache[cacheKey] = watermarkImage;
        });
      })
      .catch((error) => {

      });
  }

  handleImageLoaded = (image) => {
    const creative = this.props.creative;
    const processing = window.processingManager.processing;

    if (creative.getExtra(Creative.EXTRA_WM_ON_DEMAND, false)) {
      this.setWatermarkImage(image);
    }

    const positionInGroup = processing
      .getCreativesInGroup(creative.group, true)
      .findIndex(c => c.id === creative.id);

    logEvent(userEvents.CREATIVE_VIEW, {
      group: creative.group,
      gender: processing.getGender(),
      file_id: processing.file.id,
      template_id: creative.templateId,
      alias: creative.getExtra(Creative.EXTRA_ALIAS, ""),
      position: processing.groups.indexOf(creative.group) + 1,
      position_in_group: creative.group === creativeGroups.COLLAGE ? 1 : positionInGroup + 1,
      is_refresh: creative.isRefreshed,
      is_alt_mask: processing.getExtra(Processing.EXTRA_IS_FORKED, false),
    });

    this.setState({
      isImageLoaded: true,
    });

    this.props.onImageLoaded();
  };

  handleClick = (e) => {
    console.info(`template id: ${this.props.creative.group}/${this.props.creative.templateId}`);
  };

  render() {
    const creative = this.props.creative;

    const creativeIsProcessed = creative && creative.isProcessed;
    const creativeIsFailed = creative && creative.isFailed;
    const creativeIsPending = (creative && creative.isPending)
      || (creative && creative.isProcessed && !this.state.isImageLoaded);

    const isCollage = creativeIsProcessed && creative.getExtra(Creative.EXTRA_IS_COLLAGE, false);
    const collageWithText = isCollage && creative.getExtra(Creative.EXTRA_COLLAGE_SHOW_COMPLEX);
    const collageTooltip = collageWithText && !clientStorage.getCollageTooltipIsHidden();

    return <div className="creative-holder">
      {creativeIsProcessed && <div className="creative">
        <DumbImageView
          onClick={this.handleClick}
          image={resolveCreativeImageFile(creative)}
          onImageLoaded={this.handleImageLoaded} />

        {this.state.watermarkImage &&
          <img className="watermark-image" src={this.state.watermarkImage} alt="." />}

        {isCollage && collageWithText && this.state.isImageLoaded && <button className="btn-choose-text btn-hide-text" onClick={this.props.onToggleTextClick}>
          <img src={assetUrl(`assets/images/icon-eye-hide.png`)} alt="." />
          {i18n.t("choose_hide_text")}
        </button>}

        {isCollage && !collageWithText && this.state.isImageLoaded && <button className="btn-choose-text btn-show-text" onClick={this.props.onToggleTextClick}>
          <img src={assetUrl(`assets/images/icon-eye.png`)} alt="." />
          {i18n.t("choose_show_text")}
        </button>}

        {collageTooltip && this.state.isImageLoaded && <Tooltip />}
      </div>}

      {creativeIsPending && <CreativeLoaderView {...this.props} />}
      {creativeIsFailed && <CreativeErrorView {...this.props} />}
    </div>;
  }
}

CreativeView.propTypes = {
  creative: PropTypes.object.isRequired,
}

class Tooltip extends React.Component {
  state = {
    isHidden: false,
  };

  handleHide = () => {
    this.setState({
      isHidden: true,
    }, () => clientStorage.setCollageTooltipIsHidden(true));
  };

  render() {
    if (this.state.isHidden) {
      return <React.Fragment />;
    }

    return <div className="text-modal">
      <p>{i18n.t("result__choose_title")}</p>
      <p>{i18n.t("result__choose_text")}</p>
      <button className="btn-close-tooltip" onClick={this.handleHide}>
        <img src={assetUrl(`assets/images/icon-close.png`)} alt="." />
      </button>
    </div>;
  }
}