module.exports = {
  "strings": {
    "title": "¡PROBADO! NewProfilePic es 100 % SEGURO",

    "need_help__title": "Queridos amigos, ¡necesitamos vuestra ayuda!",
    "need_help__text_1": "En el momento en que NewProfilePic se convirtió en un éxito arrollador hace varias semanas, extraños informes han circulado en tabloides británicos y en las redes sociales diciendo hasta que NewProfilePic es un malware ruso o que nuestra aplicación está robando dinero de nuestros usuarios.",
    "need_help__text_2": "<span>Todo</span> esto es, por supuesto, una tontería, y ha sido ampliamente desacreditado por autoridades de verificación de datos como <a href=\"https://www.snopes.com/news/2022/05/11/new-profile-pic-app/\" target=\"_blank\" rel=\"noopener noreferrer\">Snopes</a> u <a href=\"https://www.politifact.com/factchecks/2022/may/16/facebook-posts/newprofilepic-app-not-russian-spy-malware-heres-ho/\" target=\"_blank\" rel=\"noopener noreferrer\">PolitiFact</a>, y organizaciones de consumidores como <a href=\"https://www.kfvs12.com/2022/05/13/bbb-says-new-profile-pic-app-isnt-leaking-your-personal-information-russia/?fr=operanews\" target=\"_blank\" rel=\"noopener noreferrer\">Better Business Bureau</a>.",
    "need_help__text_3": "Pero nos duele, ya que las noticias sensacionalistas engañosas <a href=\"https://www.instagram.com/p/CdtMShvonh2/\" target=\"_blank\" rel=\"noopener noreferrer\">tienden a perdurar más que los hechos</a>, en el sentido de “cuando el río suena, agua lleva“.",
    "need_help__text_4": "Duele en particular porque somos un grupo internacional de inspirados desarrolladores de galardonadas aplicaciones fotográficas orgullosos de nuestro trabajo y nuestra empresa.",
    "need_help__text_5": "También perjudica a los usuarios que encontraron una forma creativa de darle vida a sus fotos de perfil, pero luego sintieron que necesitaban eliminar la aplicación después de leer estas historias alarmantes.",
    "need_help__text_6": "Es hora de detener esto, ¡y puedes ayudarnos a luchar! Comparte esta página en tus redes sociales para difundir la verdad sobre NewProfilePic. ¡Y también reenvíala a cualquier amigo que pueda haberte advertido sobre los riesgos que vieron cuando usaste nuestra aplicación!",

    "about_us__title": "Acerca de nosotros",
    "about_us__text_1": "Siempre hemos estado enfocados en correr la voz sobre nuestras aplicaciones en lugar de nuestra empresa, pero, dados todas estas noticias acusatorias, nos gustaría contar un poco más sobre la empresa.",
    "about_us__text_2": "En primer lugar, ¡existimos desde hace bastante tiempo y nuestras aplicaciones siempre han tenido valoraciones magníficas en Google Play y Apple Store!",

    "about_company__title": "Algunos detalles adicionales sobre nuestra empresa",
    "about_company__2008_text_1": "Linerock Investments LTD., el nombre oficial de nuestra empresa, se registró hace más de 20 años. Nuestra empresa realmente despegó con nuestro sitio web <a href=\"https://pho.to\" target=\"_blank\" rel=\"noopener noreferrer\">https://pho.to</a> en 2008.",
    "about_company__2008_text_2": "Puede que no hayas oído hablar de nosotros antes, pero no somos tan desconocidos... cuando menos :)",
    "about_company__2017_text_1": "El mismísimo Iron Man usó nuestras aplicaciones <a href=\"https://www.facebook.com/Pho.to.Editor/posts/robert-downey-jr-chooses-photolab-to-have-the-most-impressive-photos-and-share-t/1725738937467225/\" target=\"_blank\" rel=\"noopener noreferrer\">en 2017</a>, el mismo año que Apple nos eligió como <a href=\"https://blog.pho.to/photo-labs-in-app-stores-trends-2017/\" target=\"_blank\" rel=\"noopener noreferrer\">Tendencias del año</a>.",
    "about_company__2018_text_1": "En 2018 probablemente escuchaste la historia sobre <a href=\"https://blog.pho.to/shawn-mendes-album-photolab-art/\" target=\"_blank\" rel=\"noopener noreferrer\">el diseño de la portada de los principales álbumes de música</a> sugerida por un simple aficionado… y, aparentemente, se hizo con la ayuda de nuestra aplicación.",
    "about_company__2020_text_1": "Luego, en 2020, <span className=\"bold\">Photo Lab</span> se convirtió en un <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">gran éxito</a>, llegando a la cima en varios grandes países, incluida la India. Fue entonces cuando nuestra primera tendencia, “Este rápido crecimiento es sospechoso”, comenzó a <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">vincularnos a la CIA</a>.",
    "about_company__2021_text_1": "Y, el año pasado, nuestra entonces nueva aplicación <span className=\"bold\">ToonMe</span> consiguió ser n.º 1 en posiciones generales en docenas de lugares, <a href=\"https://toonme.com/woohoo\" target=\"_blank\" rel=\"noopener noreferrer\">incluidos China, Japón e Italia</a>, mientras que <span className=\"bold\">Photo Lab</span> se convirtió en la aplicación más popular en <a href=\"https://blog.pho.to/how-2021-has-been-for-photo-lab/\" target=\"_blank\" rel=\"noopener noreferrer\">la mayor parte de África</a>.",

    "conclusion__title": "¡Así que no, no somos una compañía estafadora o fraudulenta!",
    "conclusion__text_1": "Somos una empresa con experiencia que ha trabajado duro durante muchos años desarrollando tecnologías innovadoras para crear imágenes preciosas.",
    "conclusion__text_2": "Nunca hemos participado en ninguna actividad cuestionable ni estamos afiliados a NINGÚN gobierno u organización gubernamental.",

    "any_questions": "¡Si tienes cualquier pregunta, <a href=\"mailto:newprofilepic@pho.to\">envíanos una nota</a>!",

    "share_text_1": "¡COMPARTE ESTA PÁGINA Y AYÚDANOS!",
    "share_text_2": "¡Y COMPARTE ESTA PÁGINA Y AYÚDANOS A LUCHAR!",
    "share_in_instagram": "O menciona nuestra breve página <a href=\"https://facts.pho.to\" rel=\"noopener noreferrer\">facts.pho.to</a> en Instagram",

    "btn_back_npp": "IR A LA PÁGINA PRINCIPAL",
  },
};
