import Creative from "../Creative";
import groups from "./groups";
import {combo, whenSplit} from "./helpers";
import {pwAssetUrl} from "../../utils/etc";

const celebrityCreativesPro = [
  combo(groups.CELEBRITY, "6843_pro_6885", [
    {id: 6885, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/celebrity__23661279.jpg")),

  combo(groups.CELEBRITY, "6866_pro_6886", [
    {id: 6886, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/celebrity_6866.jpg")),

  combo(groups.CELEBRITY, "6865_pro_6887", [
    {id: 6887, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/celebrity_6865.jpg")),

  combo(groups.CELEBRITY, "6859_pro_6888", [
    {id: 6888, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/celebrity_6859.jpg")),

  combo(groups.CELEBRITY, "6840_pro_6889", [
    {id: 6889, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/celebrity__6756_6821.jpg")),
];

const glossCreativesPro = [
  combo(groups.GLOSS, "6868_pro_6892", [
    {id: 6892, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/gloss_6868.jpg")),

  combo(groups.GLOSS, "6856_pro_6893", [
    {id: 6893, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setSelected(true)
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/gloss_6856.jpg")),

  combo(groups.GLOSS, "6858_pro_6894", [
    {id: 6894, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/gloss_6858.jpg")),

  combo(groups.GLOSS, "6864_pro_6897", [
    {id: 6897, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/gloss_6864.jpg")),

];

const prequelCreativesPro = [
  combo(groups.PREQUEL, "6455_6683_pro", [
    {id: 6455},
    {id: 6683, setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/6455_6683.jpg")),
];

const honeyVectorCreativesPro = [
  combo(groups.HONEYVECTOR, "6819_3757_pro", [
    {id: 6819, images: [{useAltBody: true}]},
    {id: 3757, setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/honeyvector_6819_3757.jpg")),

  combo(groups.HONEYVECTOR, "6819_3480_pro", [
    {id: 6819, images: [{useAltBody: true}]},
    {id: 3480, setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/honeyvector_6819_3480.jpg")),

  combo(groups.HONEYVECTOR, "6819_6872_pro_6873", [
    {id: 6819, images: [{useAltBody: true}]},
    {id: 6873, setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/honeyvector_6819_6872.jpg")),

  combo(groups.HONEYVECTOR, "6819_3721_pro", [
    {id: 6819, images: [{useAltBody: true}]},
    {id: 3721, setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/honeyvector_6819_3721.jpg")),

  combo(groups.HONEYVECTOR, "6819_6478_pro", [
    {id: 6819, images: [{useAltBody: true}]},
    {id: 6478, setAsFile: "raw"},
  ])
    .setSelected(true)
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/honeyvector_6819_6478.jpg")),
];

const proCreatives = [
  combo(groups.PRO, "6611_3808", [
    {id: 6611, images: [{useAltBody: true}]},
    {id: 3808, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/gloss__6611_6829.jpg")),

  combo(groups.PRO, "6683_6756", [
    {id: 6683, images: [{useAltBody: true}]},
    {id: 6756, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/alltothecute_immaculart.jpg")),

  // 23661408
  combo(groups.PRO, "6819_3465", [
    {id: 6819, images: [{useAltBody: true}]},
    {id: 3465, setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/honeyvector__23661408.jpg")),

  // 23661260
  combo(groups.PRO, "6756_1978_2856", [
    {id: 6756, images: [{useAltBody: true}]},
    {id: 1978, images: [{useAltBody: true}, {src: "https://storage.ws.pho.to/s2/620a718841f6b786f17d44fca6d43ae7232ab1ed_m.jpeg"}]},
    {id: 2856, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setSelected(true)
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/23661260.jpg")),

  combo(groups.PRO, "6819_6367", [
    {id: 6819, images: [{useAltBody: true}]},
    {id: 6367, setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/6819_6367.jpg")),

  // 23660962
  combo(groups.PRO, "6455_6632_3769", [
    {id: 6455, images: [{useAltBody: true}]},
    {id: 6632, images: [{useAltBody: true}]},
    {id: 3769, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/23660962.jpg")),
];

export default [
  ...celebrityCreativesPro,
  ...glossCreativesPro,
  ...prequelCreativesPro,
  ...honeyVectorCreativesPro,
  ...proCreatives,
];
