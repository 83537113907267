import Creative from "../Creative";
import groups from "./groups";
import {combo, frontendWatermarkStep, whenSplit} from "./helpers";
import {pwAssetUrl} from "../../utils/etc";

const celebrityCreatives = [
  combo(groups.CELEBRITY, "6843", [
    {id: 6843, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/celebrity__23661279.jpg")),

  combo(groups.CELEBRITY, "6866", [
    {id: 6866, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/celebrity_6866.jpg")),

  combo(groups.CELEBRITY, "6865", [
    {id: 6865, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/celebrity_6865.jpg")),

  combo(groups.CELEBRITY, "6859", [
    {id: 6859, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/celebrity_6859.jpg")),

  combo(groups.CELEBRITY, "6840", [
    {id: 6840, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/celebrity__6756_6821.jpg")),
];

const glossCreatives = [
  combo(groups.GLOSS, "6868", [
    {id: 6868, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/gloss_6868.jpg")),

  combo(groups.GLOSS, "6856", [
    {id: 6856, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setSelected(true)
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/gloss_6856.jpg")),

  combo(groups.GLOSS, "6858", [
    {id: 6858, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/gloss_6858.jpg")),

  combo(groups.GLOSS, "6864", [
    {id: 6864, images: [{useAltBody: true}], setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/gloss_6864.jpg")),

];

const prequelCreatives = [
  combo(groups.PREQUEL, "6455_6683", [
    {id: 6455},
    {id: 6683, setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/6455_6683.jpg"))
    .setExtra(Creative.EXTRA_WM_ON_DEMAND, true),
];

const honeyVectorCreatives = [
  combo(groups.HONEYVECTOR, "6819_3757", [
    {id: 6819, images: [{useAltBody: true}]},
    {id: 3757, setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/honeyvector_6819_3757.jpg"))
    .setExtra(Creative.EXTRA_WM_ON_DEMAND, true),

  combo(groups.HONEYVECTOR, "6819_3480", [
    {id: 6819, images: [{useAltBody: true}]},
    {id: 3480, setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/honeyvector_6819_3480.jpg"))
    .setExtra(Creative.EXTRA_WM_ON_DEMAND, true),

  combo(groups.HONEYVECTOR, "6819_6872", [
    {id: 6819, images: [{useAltBody: true}]},
    {id: 6872, setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/honeyvector_6819_6872.jpg")),

  combo(groups.HONEYVECTOR, "6819_3721", [
    {id: 6819, images: [{useAltBody: true}]},
    {id: 3721, setAsFile: "raw"},
  ])
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/honeyvector_6819_3721.jpg"))
    .setExtra(Creative.EXTRA_WM_ON_DEMAND, true),

  combo(groups.HONEYVECTOR, "6819_6478", [
    {id: 6819, images: [{useAltBody: true}]},
    {id: 6478, setAsFile: "raw"},
  ])
    .setSelected(true)
    .setExtra(Creative.EXTRA_TEMPLATE_PREVIEW_URL, pwAssetUrl("npp/previews/honeyvector_6819_6478.jpg"))
    .setExtra(Creative.EXTRA_WM_ON_DEMAND, true),
];

export default [
  ...celebrityCreatives,
  ...glossCreatives,
  ...prequelCreatives,
  ...honeyVectorCreatives,
];
