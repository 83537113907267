import React from "react";
import PropTypes from "prop-types";
import CountdownLoaderSvg from "../../components/CountdownLoaderSvg";
import {resolveCreativeImageFile} from "../../utils/creative";
import Creative from "../../photolab/Creative";

export default function TabView({
  group,
  isActive,
  onClick,
  children,
  className,
  hidden
}) {
  if (hidden) {
    return <React.Fragment />;
  }

  const creative = window.processingManager.processing.getSelectedCreativeInGroup(group);
  const isShowLoader = creative
    && creative.isPending
    && creative.getExtra(Creative.EXTRA_KEEP_PENDING, false) !== true;

  const classNames = ["btn-choice-tab"];

  classNames.push(`btn-choice-tab--${group}`);
  isActive && classNames.push("active");
  creative && creative.isPending && classNames.push("waiting");
  creative && creative.isFailed && classNames.push("error");
  className && classNames.push(className);

  const style = {};
  const previewUrl = creative && creative.isProcessed && resolveCreativeImageFile(creative);

  if (previewUrl) {
    style.backgroundImage = `url(${previewUrl})`;
  }

  return <button
    className={classNames.join(" ")}
    style={style}
    onClick={onClick}
    children={children}>
    {isShowLoader && <div className="timer-loader"><CountdownLoaderSvg duration={10} /></div>}
    {creative && creative.isFailed && <SvgErrorIcon />}
  </button>;
}

TabView.propTypes = {
  group: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

function SvgErrorIcon() {
  return <svg viewBox="0 0 24 24" className="error-icon">
    <path d="M12 0a12 12 0 1 0 12 12A12.013 12.013 0 0 0 12 0zm0 3a8.927 8.927 0 0 1 5.2 1.68L4.68 17.198A8.973 8.973 0 0 1 12 3zm0 18a8.928 8.928 0 0 1-5.2-1.68L19.32 6.802A8.973 8.973 0 0 1 12 21z" />
  </svg>;
}