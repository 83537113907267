import React from "react";
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import * as api from "../utils/api";
import {fileToJson} from "../utils/webview";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import {goToProcessing} from "../utils/url";
import clientStorage from "../utils/client-storage";
import ErrorView from "../components/ErrorView";

export default class UploadPage extends React.Component {
  state = {
    error: null,
  };

  componentDidMount() {
    logEvent(userEvents.PAGE, {page: "upload"});

    if (this.props.location.state && this.props.location.state.file) {
      this.createFile(this.props.location.state.file);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  createFile = (file) => {
    if (window.clientConfig.isWebview) {
      const fileEntity = fileToJson(file);

      this.handleFileUploaded(fileEntity);
      return;
    }

    api.createFile(file)
      .then(this.handleFileUploaded)
      .catch((error) => {
        logEvent(userEvents.PHOTO_UPLOAD_FAILED);

        this.setState({
          error
        }, this.context.hideLoader);
      });
  };

  handleFileSelected = (file) => {
    hitEvent(hits.PHOTO_SELECT);
    logEvent(userEvents.PHOTO_SELECT, {page: "upload"});

    this.context.showLoader(true, null, () => {
      this.setState({error: null}, () => {
        this.createFile(file);
      });
    });
  };

  handleFileUploaded = (file) => {
    // hitEvent(hits.PHOTO_UPLOADED);
    logEvent(userEvents.PHOTO_UPLOADED, {
      file_id: file.id,
      file_url: file.url,
      uploaded_at: Date.now(),
    });

    window.processingManager.clear();
    clientStorage.setCollageTooltipIsHidden(false);

    // const nextStepIsMask = window.clientConfig.isWebview
    //   ? window.appConfig.maskEditor.isEnabledInWebview
    //   : window.appConfig.maskEditor.isEnabledInWeb;

    const nextStepIsMask = window.clientConfig.isWeb && window.clientConfig.splitGroupId > 10; // false

    this.context.showLoader(true, file.url);

    if (nextStepIsMask) {
      this.props.history.replace({
        pathname: routes.MASK_EDITOR,
        state: {file}
      });
    } else {
      goToProcessing(this.props.history, file);
    }
  }

  render() {
    if (this.state.error) {
      return <ErrorView onFileSelected={this.handleFileSelected} />;
    }

    return <React.Fragment />;
  }
}

UploadPage.contextType = AppContext;
