module.exports = {
  "strings": {
    "title": "COMPROVADO! NewProfilePic é 100% SEGURO",

    "need_help__title": "Prezados amigos, precisamos da sua ajuda!",
    "need_help__text_1": "No momento em que o NewProfilePic se tornou um grande sucesso várias semanas atrás, relatos bizarros começaram a circular nos tabloides britânicos e nas mídias sociais, que vão desde o NewProfilePic ser um malware russo, até nosso aplicativo roubar o dinheiro de nossos usuários.",
    "need_help__text_2": "<span>Tudo</span> isso é, obviamente, um absurdo, e foi amplamente desmascarado por autoridades de verificação de fatos como <a href=\"https://www.snopes.com/news/2022/05/11/new-profile-pic-app/\" target=\"_blank\" rel=\"noopener noreferrer\">Snopes</a> ou <a href=\"https://www.politifact.com/factchecks/2022/may/16/facebook-posts/newprofilepic-app-not-russian-spy-malware-heres-ho/\" target=\"_blank\" rel=\"noopener noreferrer\">PolitiFact</a>, e organizações de consumidores como o <a href=\"https://www.kfvs12.com/2022/05/13/bbb-says-new-profile-pic-app-isnt-leaking-your-personal-information-russia/?fr=operanews\" target=\"_blank\" rel=\"noopener noreferrer\">Better Business Bureau</a>.",
    "need_help__text_3": "Ainda assim, isso nos prejudica, pois notícias sensacionalistas enganosas <a href=\"https://www.instagram.com/p/CdtMShvonh2/\" target=\"_blank\" rel=\"noopener noreferrer\">tendem a durar mais do que os fatos</a>, na linha de “onde há fumaça, há fogo”.",
    "need_help__text_4": "Isso dói, em particular, porque somos um grupo internacional de desenvolvedores inspirados de aplicativos de fotos premiados, e nos orgulhamos de nosso trabalho e de nossa empresa.",
    "need_help__text_5": "Também prejudica os usuários que primeiro encontraram uma maneira criativa de incrementar as fotos de seus perfis, mas sentiram que precisavam excluir o aplicativo depois de ler essas histórias alarmantes.",
    "need_help__text_6": "É hora de parar com isso – e você pode nos ajudar a revidar! Compartilhe esta página em suas redes sociais para divulgar a verdade sobre o NewProfilePic. E também encaminhe-a para quaisquer amigos que possam ter avisado você sobre os riscos para você pelo uso de nosso aplicativo!",

    "about_us__title": "Sobre nós",
    "about_us__text_1": "Sempre estivemos focados em divulgar nossos aplicativos em vez de nossa empresa, mas, diante de todos esses relatórios acusatórios, gostaríamos de contar um pouco mais sobre a empresa.",
    "about_us__text_2": "Em primeiro lugar, já estamos por aqui há algum tempo e nossos aplicativos sempre tiveram ótimas avaliações no Google Play e na Apple Store!",

    "about_company__title": "Alguns detalhes extras sobre a nossa empresa.",
    "about_company__2008_text_1": "Linerock Investments LTD., o nome oficial de nossa empresa, foi registrado há mais de 20 anos. Nossa empresa realmente começou com o nosso site <a href=\"https://pho.to\" target=\"_blank\" rel=\"noopener noreferrer\">https://pho.to</a> em 2008.",
    "about_company__2008_text_2": "Você pode não ter ouvido falar de nós antes, mas não estivemos completamente fora do radar... para dizer o mínimo :)",
    "about_company__2017_text_1": "O próprio Homem de Ferro usou nossos aplicativos <a href=\"https://www.facebook.com/Pho.to.Editor/posts/robert-downey-jr-chooses-photolab-to-have-the-most-impressive-photos-and-share-t/1725738937467225/\" target=\"_blank\" rel=\"noopener noreferrer\">em 2017</a>, no mesmo ano em que a Apple nos escolheu como <a href=\"https://blog.pho.to/photo-labs-in-app-stores-trends-2017/\" target=\"_blank\" rel=\"noopener noreferrer\">Tendências do ano</a>.",
    "about_company__2018_text_1": "Em 2018, você provavelmente ouviu a história sobre o design da <a href=\"https://blog.pho.to/shawn-mendes-album-photolab-art/\" target=\"_blank\" rel=\"noopener noreferrer\">capa de um grande álbum de música</a>,  sugerida por um simples fã... e que aparentemente foi feita com a ajuda do nosso aplicativo.",
    "about_company__2020_text_1": "Então, em 2020, o <span className=\"bold\">Photo Lab</span> se tornou um <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">grande sucesso</a>, alcançando o primeiro lugar em vários países enormes, incluindo a Índia. Foi quando nossa primeira tendência “Este rápido crescimento é suspeito“ começou a nos <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">ligar à CIA</a>.",
    "about_company__2021_text_1": "E no ano passado nosso então novo aplicativo <span className=\"bold\">ToonMe</span> ficou em primeiro lugar em dezenas de lugares, <a href=\"https://toonme.com/woohoo\" target=\"_blank\" rel=\"noopener noreferrer\">incluindo China, Japão e Itália</a>, enquanto o <span className=\"bold\">Photo Lab</span> se tornou o aplicativo mais popular <a href=\"https://blog.pho.to/how-2021-has-been-for-photo-lab/\" target=\"_blank\" rel=\"noopener noreferrer\">na maior parte da África</a>.",

    "conclusion__title": "Portanto, não, não somos uma empresa fraudulenta!",
    "conclusion__text_1": "Somos uma empresa madura que trabalhou duro por muitos anos desenvolvendo tecnologias inovadoras para criar belos visuais.",
    "conclusion__text_2": "Nunca estivemos envolvidos em atividades questionáveis ou afiliados a QUAISQUER governos ou organizações governamentais.",

    "any_questions": "Qualquer dúvida, por favor, entre em <a href=\"mailto:newprofilepic@pho.to\">contato conosco</a>!",

    "share_text_1": "COMPARTILHE ESTA PÁGINA E AJUDE-NOS!",
    "share_text_2": "E POR FAVOR, COMPARTILHE ESTA PÁGINA E  NOS AJUDE A REVIDAR!",
    "share_in_instagram": "Ou mencione nossa breve url <a href=\"https://facts.pho.to\" rel=\"noopener noreferrer\">facts.pho.to</a> no Instagram",

    "btn_back_npp": "IR PARA A PÁGINA PRINCIPAL",
  },
};