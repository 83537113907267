module.exports = {
  "strings": {
    "title": "PROVED! NewProfilePic is 100% SAFE",

    "need_help__title": "Dear friends, we need your help!",
    "need_help__text_1": "The moment NewProfilePic became a runaway hit several weeks ago, bizarre reports have circulated in British tabloids and on social media that range from NewProfilePic being Russian malware to our app stealing money from our users.",
    "need_help__text_2": "<span>All</span> of this is, of course, nonsense, and has been extensively debunked by fact-checking authorities like <a href=\"https://www.snopes.com/news/2022/05/11/new-profile-pic-app/\" target=\"_blank\" rel=\"noopener noreferrer\">Snopes</a> or <a href=\"https://www.politifact.com/factchecks/2022/may/16/facebook-posts/newprofilepic-app-not-russian-spy-malware-heres-ho/\" target=\"_blank\" rel=\"noopener noreferrer\">PolitiFact</a>, and consumer organisations like <a href=\"https://www.kfvs12.com/2022/05/13/bbb-says-new-profile-pic-app-isnt-leaking-your-personal-information-russia/?fr=operanews\" target=\"_blank\" rel=\"noopener noreferrer\">Better Business Bureau</a>.",
    "need_help__text_3": "Still, it hurts us, as sensational misleading news <a href=\"https://www.instagram.com/p/CdtMShvonh2/\" target=\"_blank\" rel=\"noopener noreferrer\">tends to linger longer than facts</a>, along the lines of “where there’s smoke, there is fire.”",
    "need_help__text_4": "It hurts in particular because we’re an international bunch of inspired developers of award-winning photo apps, who are proud of our work and our company.",
    "need_help__text_5": "It also hurts those users who’ve first found a creative way to spice up their profile pictures but then felt they needed to delete the app after reading these alarming stories.",
    "need_help__text_6": "It’s time to stop this – and you can help us to fight back! Please share this page on your social media to spread the truth about NewProfilePic. And also forward it to any friends who might have warned you about the risks they saw in you using our app!",

    "about_us__title": "About us",
    "about_us__text_1": "We’ve always been focused on spreading the word about our apps rather than our company, but given all these accusatory reports, we’d like to tell a bit more about the company.",
    "about_us__text_2": "First and foremost, we’ve been around for quite some time, and our apps have always had stellar reviews in the Google Play and Apple Store!",

    "about_company__title": "Some extra details about our company",
    "about_company__2008_text_1": "Linerock Investments LTD., the official name of our company, was registered over 20 years ago. Our company really got off the ground with our <a href=\"https://pho.to\" target=\"_blank\" rel=\"noopener noreferrer\">https://pho.to</a> website in 2008.",
    "about_company__2008_text_2": "You might not have heard about us earlier, but we have not been completely under the radar… to say the least :)",
    "about_company__2017_text_1": "Iron Man himself used our apps <a href=\"https://www.facebook.com/Pho.to.Editor/posts/robert-downey-jr-chooses-photolab-to-have-the-most-impressive-photos-and-share-t/1725738937467225/\" target=\"_blank\" rel=\"noopener noreferrer\">back in 2017</a>, the same year Apple chosen us as <a href=\"https://blog.pho.to/photo-labs-in-app-stores-trends-2017/\" target=\"_blank\" rel=\"noopener noreferrer\">Trends of the year</a>",
    "about_company__2018_text_1": "In 2018 you probably heard the story about <a href=\"https://blog.pho.to/shawn-mendes-album-photolab-art/\" target=\"_blank\" rel=\"noopener noreferrer\">major music album cover design</a> suggested by a simple fan… and apparently it was made with the help of our app.",
    "about_company__2020_text_1": "Then in 2020 <span className=\"bold\">Photo Lab</span> became a <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">great hit</a>, reaching <span className=\"bold\">#1 in multiple huge countries</span> including India. That’s when our first “This rapid growth is suspicious” trend started <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">linking us to CIA</a>.",
    "about_company__2021_text_1": "And last year our then-new <span className=\"bold\">ToonMe</span> app got <span className=\"bold\">#1 overall positions in dozens of places</span> <a href=\"https://toonme.com/woohoo\" target=\"_blank\" rel=\"noopener noreferrer\">including China, Japan, and Italy</a>, while <span className=\"bold\">Photo Lab</span> became <span className=\"bold\">the most popular app</span> in <a href=\"https://blog.pho.to/how-2021-has-been-for-photo-lab/\" target=\"_blank\" rel=\"noopener noreferrer\">most of Africa</a>.",

    "conclusion__title": "So no, we are not a fly-by-night scam company!",
    "conclusion__text_1": "We are mature company that has worked hard for many years by developing innovative technologies to create beautiful visuals.",
    "conclusion__text_2": "We’ve never been engaged in any questionable activities or affiliated with ANY government or governmental organisations.",

    "any_questions": "Any questions, please <a href=\"mailto:newprofilepic@pho.to\">drop us a note</a>!",

    "share_text_1": "PLEASE SHARE THIS PAGE AND HELP US!",
    "share_text_2": "AND PLEASE SHARE THIS PAGE AND HELP US FIGHT BACK!",
    "share_in_instagram": "Or mention our short <a href=\"https://facts.pho.to\" rel=\"noopener noreferrer\">facts.pho.to</a> url in Instagram",

    "btn_back_npp": "GO TO MAIN PAGE",
  },
};

