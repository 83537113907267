import React from 'react';
import routes from "../routes";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import AppContext from "../contexts/AppContext";
import {fileToJson} from "../utils/webview";
import {
  getProcessingGroups,
  getCreativesConfigs,
  resolveCreativeConfigByGroup,
} from "../photolab/config";
import creativeGroups from "../photolab/config/groups";
import Processing from "../photolab/Processing"
import Creative from "../photolab/Creative";
import {createMd5Token} from "../utils/text";
import ErrorView from "../components/ErrorView";
import * as api from "../utils/api";
import {resetNativeAdsCounter, showNativeAds} from "../utils/native-ads";

export default class ProcessingPage extends React.Component {

  state = {
    error: null,
  };

  componentDidMount() {
    logEvent(userEvents.PAGE, {page: "processing"});

    window.processingManager.addOnProcessingChangeHandler(this.handleProcessingChange);

    const loadedUrl = new URL(window.location.href);
    const fileUrlParam = loadedUrl.searchParams.get("file_url");
    const maskFileUrlParam = loadedUrl.searchParams.get("mask_file_url");
    const forkProcessingParam = loadedUrl.searchParams.get("fork_processing");
    const locationState = this.props.location.state || {};

    const file = (fileUrlParam && fileToJson(decodeURIComponent(fileUrlParam)))
      || locationState.file;

    const maskFile = (maskFileUrlParam && fileToJson(decodeURIComponent(maskFileUrlParam)))
      || locationState.maskFile;

    const doForkProcessing = (forkProcessingParam === "1")
      || locationState.forkProcessing;

    const processing = window.processingManager.restore();

    if (processing) {
      this.context.showLoader(doForkProcessing, processing.file.url);

      if (doForkProcessing) {
        this.forkProcessing(processing);
      } else {
        window.processingManager.start(processing);
      }
    } else if (file) {
      this.context.showLoader(true, file.url);
      this.startProcessing(file, maskFile);
    } else {
      this.props.history.replace(routes.INDEX);
    }
  }

  componentWillUnmount() {
    window.processingManager.removeOnProcessingChangeHandler(this.handleProcessingChange);
  }

  startProcessing = (file, maskFile) => {
    const groups = getProcessingGroups();

    const processing = new Processing();
    processing.setId(Date.now());
    processing.setFile(file);
    processing.setMaskFile(maskFile);
    processing.setGroups(groups);
    processing.setLanguage(window.clientConfig.lang);
    processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());

    getCreativesConfigs().filter((config) => config.group === creativeGroups.COMMON)
      .forEach((config) => {
        processing.addCreative(new Creative()
          .configureByConfig(config)
          .setAsSelected(true));
      });

    processing.groups.forEach((group, index) => {
      const selectedConfig = resolveCreativeConfigByGroup(group);
      if (selectedConfig) {
        const creative = new Creative()
          .configureByConfig(selectedConfig)
          .setAsSelected(true);

        if (!window.clientConfig.features.isAutoStartCreatives) {
          const startKeepPendingIndex = (window.clientConfig.splitGroupId < 11) ? 1 : 0; // true
          if (index > startKeepPendingIndex && !window.clientConfig.isPro) {
            creative.setExtra(Creative.EXTRA_KEEP_PENDING, true);
          }
        }

        processing.addCreative(creative);
      }
    });

    window.processingManager.start(processing);

    this.processingTimeoutTimer = setTimeout(
      this.handleProcessingTimeout,
      window.appConfig.processings.timeout
    );

    showNativeAds();
  };

  forkProcessing = (currentProcessing) => {
    window.processingManager.clear();

    const forkedProcessing = new Processing();
    forkedProcessing.setId(Date.now());
    forkedProcessing.setFile(currentProcessing.file);
    forkedProcessing.setMaskFile(this.props.location.state.maskFile);
    forkedProcessing.setGroups(currentProcessing.groups);
    forkedProcessing.setLanguage(currentProcessing.language);

    Object.keys(currentProcessing.extra).forEach((key) => {
      forkedProcessing.setExtra(key, currentProcessing.getExtra(key));
    });

    forkedProcessing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
    forkedProcessing.setExtra(Processing.EXTRA_IS_FORKED, true);

    currentProcessing.creatives.forEach((creative) => {
      if (creative.group === creativeGroups.COLLAGE && !creative.isSelected) {
        return;
      }

      const data = JSON.parse(JSON.stringify(creative.data));
      data.status = Creative.STATUS_PENDING;
      data.tasks = {};
      data.files = {};
      data.error = null;
      data.result = null;
      data.id = createMd5Token(Date.now(), data.group, data.templateId, data.handler);

      if (creative.group === creativeGroups.COLLAGE) {
        delete data.extra[Creative.EXTRA_CHILDREN_HASH];
        delete data.extra[Creative.EXTRA_CHILDREN_IDS];
      }

      const forkedCreative = new Creative().fromObject(data);

      forkedProcessing.addCreative(forkedCreative);
    });

    window.processingManager.start(forkedProcessing);

    this.processingTimeoutTimer = setTimeout(
      this.handleProcessingTimeout,
      window.appConfig.processings.timeout
    );
  };

  /** @param {Processing} processing */
  handleProcessingChange = (processing) => {
    if (window.appConfig.isDebug) {
      console.info("ProcessingPage::handleProcessingChange", JSON.parse(processing.toJSON()));
    }

    const selectedAndStartedCreatives = processing.creatives.filter((c) => {
      return c.isSelected
        && c.getExtra(Creative.EXTRA_KEEP_PENDING, false) !== true
        && c.group !== creativeGroups.COMMON
        && c.group !== creativeGroups.COLLAGE
      ;
    });

    const processedCreatives = selectedAndStartedCreatives.filter((c) => c.isProcessed);
    const failedCreatives = selectedAndStartedCreatives.filter((c) => c.isFailed);

    if (processedCreatives.length > 0) {
      clearTimeout(this.processingTimeoutTimer);
      hitEvent(hits.PROCESSING_PROCESSED);

      const elapsedMs = Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT);

      logEvent(userEvents.PROCESSING_PROCESSED, {elapsed_time_ms: elapsedMs});
      api.logProcessingsTimings(elapsedMs).then(() => {}).catch(() => {});

      this.props.history.replace({pathname: routes.RESULT});
    }
    else if (failedCreatives.length === selectedAndStartedCreatives.length) {
      clearTimeout(this.processingTimeoutTimer);

      const failedCreative = failedCreatives[0];

      hitEvent(hits.PROCESSING_FAILED);
      logEvent(userEvents.PROCESSING_FAILED, {
        elapsed_time_ms: Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT),
      });

      if (failedCreative.error && failedCreative.error.type === "photolab") {
        hitEvent(hits.PROCESSING_FAILED_BY_PHOTOLAB);
        resetNativeAdsCounter();
      } else {
        // window.processingManager.commitToApi(processing.toJSON());
      }

      window.processingManager.stop();

      this.setState({
        error: failedCreative.error,
      }, this.context.hideLoader);
    }
  };

  handleFileSelected = (file) => {
    hitEvent(hits.PHOTO_SELECT);
    logEvent(userEvents.PHOTO_SELECT, {page: "processing"});

    this.context.showLoader(true, null, () => {
      this.props.history.replace(routes.UPLOAD, {file});
    });
  };

  handleProcessingTimeout = () => {
    clearTimeout(this.processingTimeoutTimer);

    hitEvent(hits.PROCESSING_TIMEOUT);
    window.processingManager.stop();
    window.processingManager.clear();

    this.setState({
      error: {
        type: "processing_timeout",
        code: 1,
        message: "timeout",
      },
    }, this.context.hideLoader);
  };

  render() {
    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        onFileSelected={this.handleFileSelected}
      />;
    }

    return <React.Fragment />;
  }
}

ProcessingPage.contextType = AppContext;
