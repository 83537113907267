module.exports = {
  "strings": {
    "title": "BEWIESEN! NewProfilePic ist 100% SICHER",

    "need_help__title": "Liebe Freunde, wir brauchen Ihre Hilfe!",
    "need_help__text_1": "Dass NewProfilePic zum Renner wurde, ist erst einige Wochen her und seitdem kursierten bizarre Berichte in der britischen Boulevardpresse sowie den sozialen Medien, die bis dahin gingen zu behaupten, dass es sich bei NewProfilePic um russische Schadsoftware handle und unsere App Geld von unseren Nutzern stehle.",
    "need_help__text_2": "<span>All</span> dies ist natürlich Unsinn und wurde von faktenprüfenden Instanzen wie <a href=\"https://www.snopes.com/news/2022/05/11/new-profile-pic-app/\" target=\"_blank\" rel=\"noopener noreferrer\">Snopes</a> oder <a href=\"https://www.politifact.com/factchecks/2022/may/16/facebook-posts/newprofilepic-app-not-russian-spy-malware-heres-ho/\" target=\"_blank\" rel=\"noopener noreferrer\">PolitiFact</a> und Verbraucherorganisationen wie <a href=\"https://www.kfvs12.com/2022/05/13/bbb-says-new-profile-pic-app-isnt-leaking-your-personal-information-russia/?fr=operanews\" target=\"_blank\" rel=\"noopener noreferrer\">Better Business Bureau</a> umfassend widerlegt.",
    "need_help__text_3": "Dennoch schmerzt es uns, da sensationslüsterne, irreführende Nachrichten in der <a href=\"https://www.instagram.com/p/CdtMShvonh2/\" target=\"_blank\" rel=\"noopener noreferrer\">Regel länger nachwirken als Fakten</a>, ganz nach dem Motto „wo Rauch ist, ist auch Feuer“.",
    "need_help__text_4": "Das tut besonders weh, da wir ein internationaler Haufen inspirierter Entwickler von preisgekrönten Foto-Apps sind, die stolz auf unsere Arbeit und unser Unternehmen sind.",
    "need_help__text_5": "Es schmerzt auch jene Nutzer, die zunächst eine kreative Möglichkeit gefunden haben, ihre Profilbilder aufzupeppen, dann aber das Gefühl hatten, die App löschen zu müssen, nachdem sie diese alarmierenden Geschichten gelesen hatten.",
    "need_help__text_6": "Es ist an der Zeit, dem ein Ende zu setzen – und Sie können uns helfen, uns zu wehren! Bitte teilen Sie diese Seite in Ihren sozialen Medien, um die Wahrheit über NewProfilePic zu verbreiten. Und leiten Sie sie auch an alle Freunde weiter, die Sie vor den Risiken gewarnt haben, welche sie in der Nutzung unserer App sahen!",

    "about_us__title": "Über uns",
    "about_us__text_1": "Wir haben uns immer darauf konzentriert, mehr über unsere Apps als über unser Unternehmen zu berichten, doch angesichts all dieser anklagenden Berichte würden wir gerne ein wenig mehr über das Unternehmen erzählen.",
    "about_us__text_2": "Zunächst und vor allem gibt es uns schon seit geraumer Zeit, und unsere Apps haben im Google Play und Apple Store immer hervorragende Bewertungen erhalten!",

    "about_company__title": "Einige zusätzliche Details über unser Unternehmen",
    "about_company__2008_text_1": "Linerock Investments LTD., der offizielle Name unseres Unternehmens, wurde vor über 20 Jahren registriert. Unser Unternehmen ist 2008 mit unserer Website <a href=\"https://pho.to\" target=\"_blank\" rel=\"noopener noreferrer\">https://pho.to</a> richtig durchgestartet.",
    "about_company__2008_text_2": "Sie haben vielleicht noch nicht von uns gehört, doch wir sind nicht völlig unbemerkt geblieben ... gelinde gesagt :)",
    "about_company__2017_text_1": "Iron Man selbst nutzte unsere Apps <a href=\"https://www.facebook.com/Pho.to.Editor/posts/robert-downey-jr-chooses-photolab-to-have-the-most-impressive-photos-and-share-t/1725738937467225/\" target=\"_blank\" rel=\"noopener noreferrer\">im Jahr 2017</a>, demselben Jahr, in dem Apple uns zum <a href=\"https://blog.pho.to/photo-labs-in-app-stores-trends-2017/\" target=\"_blank\" rel=\"noopener noreferrer\">Trend des Jahres</a> kürte",
    "about_company__2018_text_1": "Im Jahr 2018 haben Sie wahrscheinlich die Geschichte über <a href=\"https://blog.pho.to/shawn-mendes-album-photolab-art/\" target=\"_blank\" rel=\"noopener noreferrer\">das Design eines großen Musikalbums gehört</a>, das von einem einfachen Fan vorgeschlagen wurde ... und offenbar wurde es mit Hilfe unserer App erstellt.",
    "about_company__2020_text_1": "Im Jahr 2020 wurde <span className=\"bold\">Photo Lab</span> dann ein <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">großer Erfolg</a> und erreichte in mehreren großen Ländern, darunter Indien, Platz 1. Damals begann unser erster „Dieses schnelle Wachstum ist verdächtig“-Trend, der uns <a href=\"https://blog.pho.to/how-photo-lab-is-not-connected-to-the-cia/\" target=\"_blank\" rel=\"noopener noreferrer\">mit der CIA</a> in Verbindung brachte.",
    "about_company__2021_text_1": "Und im letzten Jahr war unsere damals neue <span className=\"bold\">ToonMe-App</span> in <a href=\"https://toonme.com/woohoo\" target=\"_blank\" rel=\"noopener noreferrer\">Dutzenden von Ländern, darunter China, Japan und Italien</a>, die Nummer 1 in der Gesamtbewertung, während <span className=\"bold\">Photo Lab</span> in <a href=\"https://blog.pho.to/how-2021-has-been-for-photo-lab/\" target=\"_blank\" rel=\"noopener noreferrer\">fast ganz Afrika</a> die beliebteste App wurde.",

    "conclusion__title": "Also nein, wir sind keine Eintagsfliege und kein Betrugsunternehmen!",
    "conclusion__text_1": "Wir sind ein ausgereiftes Unternehmen, das seit vielen Jahren hart an der Entwicklung innovativer Technologien zur Erstellung schöner Bilder arbeitet.",
    "conclusion__text_2": "Wir waren nie in fragwürdige Aktivitäten verwickelt oder mit irgendeiner Regierung oder Regierungsorganisation verbunden.",

    "any_questions": "<a href=\"mailto:newprofilepic@pho.to\">Schreiben Sie uns bitte eine Nachricht</a>, falls Sie Fragen haben!",

    "share_text_1": "BITTE TEILEN SIE DIESE SEITE UND HELFEN SIE UNS!",
    "share_text_2": "UND BITTE TEILEN SIE DIESE SEITE UND HELFEN SIE UNS, UNS ZU WEHREN!",
    "share_in_instagram": "Oder erwähnen Sie unsere kurze <a href=\"https://facts.pho.to\" rel=\"noopener noreferrer\">facts.pho.to-Url</a> auf Instagram",

    "btn_back_npp": "ZUR HAUPTSEITE WECHSELN",
  },
};
