import React from "react";
import i18n from "../i18n";
import {openWindow} from "../utils/window";
import {assetUrl} from "../utils/etc";
import AppContext from "../contexts/AppContext";
import {webviewShareFacebook, webviewShareTelegram} from "../utils/webview";
import {hitEvent} from "../utils/log";
import clientStorage from "../utils/client-storage";

const defaultLang = "en";

const langs = [
  "en",
  "fr",
  "de",
  "it",
  "es",
  "ru",
  "pt",
];

const flags = {
  "en": "assets/images/article/flags/us.jpg",
  "fr": "assets/images/article/flags/fr.jpg",
  "de": "assets/images/article/flags/de.jpg",
  "it": "assets/images/article/flags/it.jpg",
  "es": "assets/images/article/flags/es.jpg",
  "ru": "assets/images/article/flags/ru.jpg",
  "pt": "assets/images/article/flags/br.jpg",
}

export default class SafeAndCoolPage extends React.Component {

  state = {
    variant: 1,
    content: "",
    lang: "",
    isLangDropdownActive: false,
  }

  constructor(props) {
    super(props);

    this.state.variant = window.clientConfig.splitGroupId > 5 ? 2 : 1;
    //this.state.variant = 1;
  }

  componentDidMount() {
    this.loadedURL = new URL(window.location.href);

    let lang = this.loadedURL.searchParams.get("lang")
      || clientStorage.getSelectedLang()
      || window.clientConfig.lang;

    if (langs.findIndex((l) => l === lang) === -1) {
      lang = defaultLang;
    }

    i18n.changeLanguage(lang, () => {
      this.setState({lang});
    });

    this.shareUrl = new URL((this.state.variant === 2
        ? "https://facts.pho.to/help"
        : "https://facts.pho.to/"
    ) + "?lang=" + lang);

    if (this.loadedURL.searchParams.has("app")) {
      this.hitUnique("help_visit", 9037, 9038);

      const url = new URL(this.loadedURL.toString());
      url.searchParams.delete("app");

      window.history.replaceState({}, document.title, url.toString());
    } else {
      this.hitUnique("help_visit", 9035, 9036);
    }

    window.pushOnBackPressed(this.handleBackPressed);
  }

  componentWillUnmount() {
    window.popOnBackPressed();
  }

  toggleLangDropdown = () => {
    this.setState({
      isLangDropdownActive: !this.state.isLangDropdownActive,
    })
  }

  handleChangeLang = (lang) => {
    i18n.changeLanguage(lang, () => {
      this.setState({
        lang,
        isLangDropdownActive: false,
      }, () => {
        clientStorage.setSelectedLang(lang);
      });
    });

    const url = new URL(window.location.href);
    url.searchParams.set("lang", lang);

    window.history.replaceState({}, document.title, url.toString());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.shareUrl = new URL((this.state.variant === 2
        ? "https://facts.pho.to/help"
        : "https://facts.pho.to/"
    ) + "?lang=" + this.state.lang);

    document.title = i18n.t("title", null, {language: this.state.lang});
  }

  handleBackPressed = () => {
    this.props.history.goBack();
    return true;
  };

  hitUnique = (name, hit1, hit2) => {
    const key = "npp:hits:" + name;
    const value = window.localStorage.getItem(key);

    if (value !== "true") {
      hitEvent(this.state.variant === 2 ? hit2 : hit1, 1, true);
      window.localStorage.setItem(key, "true");
    }
  };

  handleTelegramShareClick = () => {
    if (window.clientConfig.isWeb) {
      openWindow(
        `https://telegram.me/share/url?url=${encodeURIComponent(this.shareUrl.toString())}`,
        "_blank"
      );
    } else {
      webviewShareTelegram(this.shareUrl.toString());
    }
  };

  handleFacebookShareClick = () => {
    this.hitUnique("help_share_facebook", 9028, 9029);

    if (window.clientConfig.isWeb) {
      // const facebookAppId = 202248336556242;
      openWindow(
        // `https://www.facebook.com/dialog/share?app_id=${facebookAppId}&display=popup&href=${encodeURIComponent(this.shareUrl.toString())}`,
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.shareUrl.toString())}`,
        "_blank"
      );
    } else {
      webviewShareFacebook(this.shareUrl.toString());
    }
  };

  handleTwitterShareClick = () => {
    this.hitUnique("help_share_twitter", 9030, 9031);

    openWindow(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.shareUrl.toString())}`,
      "_blank",
      {width: 840, height: 620}
    );
  };

  handleVKShareClick = () => {
    openWindow(
      `https://vk.com/share.php?url=${encodeURIComponent(this.shareUrl.toString())}`,
      "_blank"
    );
  };

  handleNativeShareClick = () => {
    this.hitUnique("help_share_native", 9033, 9034);

    window.navigator.share({url: this.shareUrl.toString()})
      .then(() => {/* omit */})
      .catch((err) => {
        if (err.name !== "AbortError") {
          //
        }
      });
  };

  render() {
    const showFacebookShareButton = window.clientConfig.isWeb || this.context.installedApps.facebook;
    const showTwitterShareButton = window.clientConfig.isWeb;
    const showNativeShateButton = window.clientConfig.isWeb && typeof window.navigator.share === "function";

    return <div className="safe-page">
      {/* <div dangerouslySetInnerHTML={{__html: this.state.content}} /> */}
      <div className="container">
          <header>

            <div className={"lang-change" + (this.state.isLangDropdownActive ? " lang-change-open" : "")}>
              <button className="lang-change-main-btn" onClick={this.toggleLangDropdown}>
                <div className="lang-change-flag">
                  <img src={assetUrl(flags[this.state.lang])} alt={this.state.lang} />
                </div>
                <h3 className="lang-change-name">{this.state.lang.toUpperCase()}</h3>
                <svg className="lang-change-icon" viewBox="0 0 24 24">
                  <path d="M12 15.753a.752.752 0 0 1-.533-.217l-6-6A.753.753 0 1 1 6.532 8.47l5.467 5.475 5.468-5.475a.753.753 0 1 1 1.065 1.065l-6 6a.75.75 0 0 1-.533.217z" fill="#000"/>
                </svg>
              </button>
              <ul hidden={!this.state.isLangDropdownActive}>
                {langs.map((lang) => {
                  if (lang === this.state.lang) {
                    return;
                  }

                  return <li key={lang}>
                    <button onClick={() => this.handleChangeLang(lang)}>
                      <div className="lang-change-flag">
                        <img src={assetUrl(flags[lang])} alt={lang} />
                      </div>
                      <h3 className="lang-change-name">{lang.toUpperCase()}</h3>
                    </button>
                  </li>
                })}
              </ul>
            </div>
          </header>
          <div className="section-1">
            <div className="section-1-text">
              <h1 dangerouslySetInnerHTML={{__html: i18n.t("need_help__title")}} />
              <p style={{marginBottom: "40px"}} dangerouslySetInnerHTML={{__html: i18n.t("need_help__text_1")}} />
              <p dangerouslySetInnerHTML={{__html: i18n.t("need_help__text_2")}} />
              <img className="desktop" src={assetUrl(`assets/images/article/main_.png`)} alt="" />
              <img className="mob" src={assetUrl(`assets/images/article/mob.png`)} alt="" />
            </div>
          </div>

        {/*this.state.variant === 2 && <div className="section-2">
          <p>Because it appeared that flashy fake news <br /><a style={{textDecoration:"none"}}> spread much  faster and better than the truth</a>.</p>
        </div>*/}

          <div className="section-3 pl-200">
            <p dangerouslySetInnerHTML={{__html: i18n.t("need_help__text_3")}} />
            <p dangerouslySetInnerHTML={{__html: i18n.t("need_help__text_4")}} />
            <p dangerouslySetInnerHTML={{__html: i18n.t("need_help__text_5")}} />
            <p className="mb-0" dangerouslySetInnerHTML={{__html: i18n.t("need_help__text_6")}} />

            <div className="social-block">
              <h3 dangerouslySetInnerHTML={{__html: i18n.t("share_text_1")}} />
              <ul>
                <li hidden={!showFacebookShareButton}>
                  <button onClick={this.handleFacebookShareClick}>
                    <svg viewBox="0 0 48 48">
                      <path fillRule="evenodd" clipRule="evenodd" d="M25.638 48H2.65A2.65 2.65 0 0 1 0 45.35V2.65A2.649 2.649 0 0 1 2.65 0H45.35A2.649 2.649 0 0 1 48 2.65v42.7A2.65 2.65 0 0 1 45.351 48H33.119V29.412h6.24l.934-7.245h-7.174v-4.625c0-2.097.583-3.526 3.59-3.526l3.836-.002V7.535c-.663-.088-2.94-.285-5.59-.285-5.53 0-9.317 3.376-9.317 9.576v5.342h-6.255v7.244h6.255V48z" fill="#4460A0"/>
                    </svg>
                  </button>
                </li>
                <li hidden={!showTwitterShareButton}>
                  <button onClick={this.handleTwitterShareClick}>
                    <svg viewBox="0 0 48 48">
                      <g clipPath="url(#cgxlya2gea)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M48 8.735a19.23 19.23 0 0 1-5.655 1.589 10.077 10.077 0 0 0 4.33-5.585 19.394 19.394 0 0 1-6.257 2.448C38.626 5.225 36.065 4 33.231 4c-5.435 0-9.844 4.521-9.844 10.098 0 .791.084 1.56.253 2.3-8.184-.423-15.439-4.439-20.299-10.555a10.283 10.283 0 0 0-1.333 5.082c0 3.502 1.739 6.593 4.381 8.405a9.67 9.67 0 0 1-4.462-1.26v.124c0 4.894 3.394 8.977 7.902 9.901a9.387 9.387 0 0 1-2.595.356c-.633 0-1.253-.061-1.854-.18 1.254 4.01 4.888 6.932 9.199 7.01-3.37 2.71-7.618 4.325-12.23 4.325-.795 0-1.58-.047-2.35-.14C4.358 42.327 9.536 44 15.095 44c18.116 0 28.019-15.385 28.019-28.73 0-.439-.01-.878-.026-1.309A20.199 20.199 0 0 0 48 8.735z" fill="#00AAEC"/>
                      </g>
                      <defs>
                        <clipPath id="cgxlya2gea">
                          <path fill="#fff" d="M0 0h48v48H0z"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </li>
                <li hidden={!showNativeShateButton}>
                  <button onClick={this.handleNativeShareClick}>
                    <svg viewBox="0 0 48 48">
                      <path d="M45.176 35.295v-9.883c0-1.694-1.129-2.823-2.823-2.823s-2.824 1.13-2.824 2.824v9.882c0 3.953-3.106 7.059-7.058 7.059H12.706c-3.953 0-7.059-3.106-7.059-7.06V15.53c0-3.953 3.106-7.059 7.059-7.059h9.882c1.694 0 2.824-1.13 2.824-2.823 0-1.694-1.13-2.824-2.824-2.824h-9.882A12.653 12.653 0 0 0 0 15.53v19.765A12.653 12.653 0 0 0 12.706 48H32.47a12.653 12.653 0 0 0 12.706-12.706z" fill="#00BA7B"/>
                      <path d="M48 16.941V2.824c0-.283 0-.848-.282-1.13A3.403 3.403 0 0 0 46.306.282C46.024 0 45.459 0 45.176 0H31.623C29.93 0 28.8 1.13 28.8 2.824s1.13 2.823 2.823 2.823H38.4L20.612 23.435c-1.13 1.13-1.13 2.824 0 3.953.564.565 1.412.847 1.976.847.565 0 1.412-.282 1.977-.847L42.353 9.6v7.341c0 1.694 1.13 2.824 2.823 2.824 1.694 0 2.824-1.13 2.824-2.824z" fill="#00BA7B"/>
                    </svg>
                  </button>
                </li>
              </ul>
              <p dangerouslySetInnerHTML={{__html: i18n.t("share_in_instagram")}} />
            </div>
          </div>

          <div className="section-8">
              <h2 dangerouslySetInnerHTML={{__html: i18n.t("about_us__title")}} />
              <p dangerouslySetInnerHTML={{__html: i18n.t("about_us__text_1")}} />
              <p dangerouslySetInnerHTML={{__html: i18n.t("about_us__text_2")}} />
          </div>

          {this.state.variant === 2 && this.state.lang === "en" &&
            <div className="section-9">
              <h2>Myths vs <span>Reality</span></h2>
              <div className="section-9-compare-block">
                <div className="section-9-compare">
                  <h3 className="yellow">This is what’s being said about us</h3>
                  <div className="section-9-compare-content">
                    <div className="section-9-compare-logos section-9-compare-logos-border">
                      <img className="h32" src={assetUrl(`assets/images/article/mail.png`)} alt=""/>
                      <img className="h40" src={assetUrl(`assets/images/article/mirror.png`)} alt=""/>
                      <img className="h40" src={assetUrl(`assets/images/article/news.png`)} alt=""/>
                    </div>
                    <ul className="error-list">
                      <li>Steals your data</li>
                      <li>Being connected to the Kremlin</li>
                      <li>Sends data to Moscow</li>
                      <li>Malware</li>
                      <li>Taking money out of accounts</li>
                    </ul>
                  </div>
                </div>
                <div className="section-9-compare section-9-compare-green">
                  <h3 className="green">This is how it really is</h3>
                  <div className="section-9-compare-content section-9-compare-content-green">
                    <div className="section-9-compare-logos">
                      <img className="h56" src={assetUrl(`assets/images/article/bbb.png`)} alt=""/>
                    </div>
                    <ul className="check-list">
                      <li>Zero threat</li>
                      <li><span>“We have not received any scam tracking reports that have caused concern,”</span> Angela
                        Guth, BBB SWLA President/CEO said
                      </li>
                      <li>The app does collect some user data, but its privacy policy isn’t out of the ordinary</li>
                    </ul>
                    <div className="section-9-compare-logos mt56">
                      <img className="h64" src={assetUrl(`assets/images/article/snopes.png`)} alt=""/>
                    </div>
                    <ul className="check-list">
                      <li>British Virgin Islands company.</li>
                      <li>They use Amazon AWS and Microsoft Azure, the servers located in the U.S.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="section-4">
            <h2 dangerouslySetInnerHTML={{__html: i18n.t("about_company__title")}} />
            <div className="section-4-block">
              <p className="section-4-block-text" dangerouslySetInnerHTML={{__html: i18n.t("about_company__2008_text_1")}} />
              <p className="section-4-block-date">2008</p>
            </div>
            
            <div className="section-4-block">
              <p className="section-4-block-text section-4-block-text2" dangerouslySetInnerHTML={{__html: i18n.t("about_company__2008_text_2")}} />
              <p className="section-4-block-date" />
            </div>

            <div className="section-4-block">
              <p className="section-4-block-text" dangerouslySetInnerHTML={{__html: i18n.t("about_company__2017_text_1")}} />
              <p className="section-4-block-date">2017</p>
            </div>

            <div className="section-4-block">
              <p className="section-4-block-text section-4-block-text2 section-4-block-text2-empty" />
              <p className="section-4-block-date" />
            </div>

            <div className="section-4-block">
              <p className="section-4-block-text" dangerouslySetInnerHTML={{__html: i18n.t("about_company__2018_text_1")}} />
              <p className="section-4-block-date">2018</p>
            </div>

            <div className="section-4-block">
              <p className="section-4-block-text section-4-block-text2 section-4-block-text2-empty" />
              <p className="section-4-block-date" />
            </div>

            <div className="section-4-block">
              <p className="section-4-block-text" dangerouslySetInnerHTML={{__html: i18n.t("about_company__2020_text_1")}} />
              <p className="section-4-block-date">2020</p>
            </div>

            <div className="section-4-block">
              <p className="section-4-block-text section-4-block-text2 section-4-block-text2-empty" />
              <p className="section-4-block-date" />
            </div>

            <div className="section-4-block">
              <p className="section-4-block-text" dangerouslySetInnerHTML={{__html: i18n.t("about_company__2021_text_1")}} />
              <p className="section-4-block-date">2021</p>
            </div>
          </div>

          <div className="section-5 pl-200">
            <h2 dangerouslySetInnerHTML={{__html: i18n.t("conclusion__title")}} />
            <p dangerouslySetInnerHTML={{__html: i18n.t("conclusion__text_1")}} />
            <p dangerouslySetInnerHTML={{__html: i18n.t("conclusion__text_2")}} />
          </div>

          <div className="section-5" style={{marginTop: "40px"}}>
            <p dangerouslySetInnerHTML={{__html: i18n.t("any_questions")}} />
          </div>

          <div className="social-block">
            <h3 dangerouslySetInnerHTML={{__html: i18n.t("share_text_2")}} />
            <ul>
              <li hidden={!showFacebookShareButton}>
                <button onClick={this.handleFacebookShareClick}>
                  <svg viewBox="0 0 48 48">
                    <path fillRule="evenodd" clipRule="evenodd" d="M25.638 48H2.65A2.65 2.65 0 0 1 0 45.35V2.65A2.649 2.649 0 0 1 2.65 0H45.35A2.649 2.649 0 0 1 48 2.65v42.7A2.65 2.65 0 0 1 45.351 48H33.119V29.412h6.24l.934-7.245h-7.174v-4.625c0-2.097.583-3.526 3.59-3.526l3.836-.002V7.535c-.663-.088-2.94-.285-5.59-.285-5.53 0-9.317 3.376-9.317 9.576v5.342h-6.255v7.244h6.255V48z" fill="#4460A0"/>
                  </svg>
                </button>
              </li>
              <li hidden={!showTwitterShareButton}>
                <button onClick={this.handleTwitterShareClick}>
                  <svg viewBox="0 0 48 48">
                    <g clipPath="url(#cgxlya2gea)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M48 8.735a19.23 19.23 0 0 1-5.655 1.589 10.077 10.077 0 0 0 4.33-5.585 19.394 19.394 0 0 1-6.257 2.448C38.626 5.225 36.065 4 33.231 4c-5.435 0-9.844 4.521-9.844 10.098 0 .791.084 1.56.253 2.3-8.184-.423-15.439-4.439-20.299-10.555a10.283 10.283 0 0 0-1.333 5.082c0 3.502 1.739 6.593 4.381 8.405a9.67 9.67 0 0 1-4.462-1.26v.124c0 4.894 3.394 8.977 7.902 9.901a9.387 9.387 0 0 1-2.595.356c-.633 0-1.253-.061-1.854-.18 1.254 4.01 4.888 6.932 9.199 7.01-3.37 2.71-7.618 4.325-12.23 4.325-.795 0-1.58-.047-2.35-.14C4.358 42.327 9.536 44 15.095 44c18.116 0 28.019-15.385 28.019-28.73 0-.439-.01-.878-.026-1.309A20.199 20.199 0 0 0 48 8.735z" fill="#00AAEC"/>
                    </g>
                    <defs>
                      <clipPath id="cgxlya2gea">
                        <path fill="#fff" d="M0 0h48v48H0z"/>
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </li>
              <li hidden={!showNativeShateButton}>
                <button onClick={this.handleNativeShareClick} children="native">
                  <svg viewBox="0 0 48 48">
                      <path d="M45.176 35.295v-9.883c0-1.694-1.129-2.823-2.823-2.823s-2.824 1.13-2.824 2.824v9.882c0 3.953-3.106 7.059-7.058 7.059H12.706c-3.953 0-7.059-3.106-7.059-7.06V15.53c0-3.953 3.106-7.059 7.059-7.059h9.882c1.694 0 2.824-1.13 2.824-2.823 0-1.694-1.13-2.824-2.824-2.824h-9.882A12.653 12.653 0 0 0 0 15.53v19.765A12.653 12.653 0 0 0 12.706 48H32.47a12.653 12.653 0 0 0 12.706-12.706z" fill="#00BA7B"/>
                      <path d="M48 16.941V2.824c0-.283 0-.848-.282-1.13A3.403 3.403 0 0 0 46.306.282C46.024 0 45.459 0 45.176 0H31.623C29.93 0 28.8 1.13 28.8 2.824s1.13 2.823 2.823 2.823H38.4L20.612 23.435c-1.13 1.13-1.13 2.824 0 3.953.564.565 1.412.847 1.976.847.565 0 1.412-.282 1.977-.847L42.353 9.6v7.341c0 1.694 1.13 2.824 2.823 2.824 1.694 0 2.824-1.13 2.824-2.824z" fill="#00BA7B"/>
                  </svg>
                </button>
              </li>
            </ul>
            <p dangerouslySetInnerHTML={{__html: i18n.t("share_in_instagram")}} />
          </div>

          <a href="https://newprofilepic.com/" className="btn-back-npp" dangerouslySetInnerHTML={{__html: i18n.t("btn_back_npp")}}></a>
        </div>
    </div>
  }
}

SafeAndCoolPage.contextType = AppContext;


