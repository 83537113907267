import proConfig from "./config-pro";
import freeConfig from "./config-free";
import groups from "./groups";
import Creative from "../Creative";

export function getProcessingGroups() {
  let shouldHidePROGroups = false;
  let processingGroups = [
    groups.CELEBRITY,
    groups.GLOSS,
    groups.HONEYVECTOR,
    groups.PREQUEL,
  ];

  if (window.clientConfig.isPro) {
    processingGroups.push(groups.PRO);
  }

  if (window.clientConfig.isWebview) {
    const buildVersion = parseInt(window.clientConfig.webviewParams["version_code"]);

    if (window.clientConfig.isWebviewAndroid) {
      shouldHidePROGroups = buildVersion < 26;
    } else {
      shouldHidePROGroups = buildVersion < 76;
    }
  } else {
    shouldHidePROGroups = true;
  }

  if (shouldHidePROGroups) {
    processingGroups = processingGroups.filter((group) => {
      return resolveCreativeConfigByGroup(group).getExtra(Creative.EXTRA_IS_PRO) !== true;
    });
  }

  return processingGroups;
}

export function getCreativesConfigs() {
  return window.clientConfig.isPro ? proConfig : freeConfig;
}

/**
 * @param {string} group
 * @returns {CreativeConfig}
 */
export function resolveCreativeConfigByGroup(group) {
  const configs = getCreativesConfigs().filter((config) => config.group === group);
  let selectedConfig = configs.find((config) => config.isSelected);
  if (!selectedConfig) {
    const sum = configs.reduce((value, config) => value + config.chanceToSelect, 0);
    if (sum === 0) {
      selectedConfig = configs[0];
    } else {
      let chance = Math.random() * sum;
      for (let i = 0; i < configs.length; i++) {
        chance -= configs[i].chanceToSelect;
        if (chance <= 0) {
          selectedConfig = configs[i];
          break;
        }
      }

      if (!selectedConfig) {
        selectedConfig = configs[0];
      }
    }
  }

  return selectedConfig;
}
