import React from 'react';
import routes from "../../routes";
import AppContext from "../../contexts/AppContext";
import {debounce} from "../../utils/etc";
import creativeGroups from "../../photolab/config/groups";
import TabView from "./TabView";
import TabContentView from "./TabContentView";
import {hitEvent, hits, logEvent, userEvents} from "../../utils/log";
import DownloadModal from "../../components/DownloadModal";
import RateAppModal from "../../components/RateAppModal";
import Processing from "../../photolab/Processing";
import {
  resolveCreativeImageFile,
  transformToDownloadUrl
} from "../../utils/creative";
import * as webviewUtils from "../../utils/webview";
import clientStorage from "../../utils/client-storage";
import {saveAs} from "file-saver";
import PhotolabAdTabContent from "./PhotolabAdTabContent";
import Creative from "../../photolab/Creative";
import {webviewShowBanner} from "../../utils/webview";
import {SLIDES as PHOTOLAB_AD_SLIDES, getSrcSet as getPhotolabAdSrcSet} from "./PhotolabAdTabContent";
import {watermarkStep} from "../../photolab/handlers/combo.handler";
import {frontendWatermarkStep} from "../../photolab/config/helpers";

const TAB_PRO_PROMOTION = "pro_promotion";
const TAB_PHOTOLAB_PROMOTION = "photolab_tab_ad";

function loadPhotolabAdSlides() {
  const jpg = PHOTOLAB_AD_SLIDES.slice();
  const webp = PHOTOLAB_AD_SLIDES.slice();

  const jpgImage = new Image();
  jpgImage.onload = () => {
    const jpgSlide = jpg.shift();
    if (jpgSlide) {
      jpgImage.srcset = getPhotolabAdSrcSet(jpgSlide, "jpg");
    }
  };

  jpgImage.srcset = getPhotolabAdSrcSet(jpg.shift(), "jpg");

  const webpImage = new Image();
  webpImage.onload = () => {
    const webpSlide = webp.shift();
    if (webpSlide) {
      webpImage.srcset = getPhotolabAdSrcSet(webpSlide, "webp");
    }
  };
  webpImage.srcset = getPhotolabAdSrcSet(webp.shift(), "webp");
}

export default class ResultPage extends React.Component {

  state = {
    isReady: false,
    tab: "",
    tabs: [],
  };

  hitProcessingFailedOnResultPageIsTriggered = false;

  componentDidMount() {
    this.tabsClicks = [];

    logEvent(userEvents.PAGE, {page: "result"});

    loadPhotolabAdSlides();

    window.processingManager.addOnProcessingChangeHandler(this.handleProcessingChangedDebug);
    window.processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    if (window.processingManager.processing === null) {
      const restoredProcessing = window.processingManager.restore();
      if (restoredProcessing) {
        try {
          if (window.clientConfig.isPro) {
            restoredProcessing.creatives.forEach((creative) => {
              if (creative.getExtra(Creative.EXTRA_KEEP_PENDING)) {
                creative.removeExtra(Creative.EXTRA_KEEP_PENDING);
              }
            })
          }

          window.processingManager.start(restoredProcessing);
          this.setState({isReady: true});
        } catch (err) {
          console.error(err);

          this.props.history.replace(routes.INDEX);
          return;
        }
      } else {
        this.props.history.replace(routes.INDEX);
        return;
      }
    } else {
      this.setState({isReady: true});
    }

    this.handleProcessingChanged();
  }

  componentWillUnmount() {
    window.processingManager.removeOnProcessingChangeHandler(this.handleProcessingChangedDebug);
    window.processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);
  }

  handleProcessingChangedDebug = () => {
    if (window.appConfig.isDebug) {
      const cloned = JSON.parse(window.processingManager.processing.toJSON(true));
      console.info("ResultPage::handleProcessingChanged", cloned);
    }
  };

  handleProcessingChanged = () => debounce("ResultPage_handleProcessingChanged", 100, () => {
    const processing = window.processingManager.processing;
    const firstTab = processing.getExtra(Processing.SELECTED_GROUP) || processing.groups[0];
    const firstTabCreatives = processing.getCreativesInGroup(firstTab);
    firstTabCreatives.first().setExtra(Creative.EXTRA_VIEW_OPENED, true);

    this.context.hideLoader();

    hitFirstTabClick(processing, firstTab, false);

    this.setState({
      tab: firstTab,
      tabs: [...processing.groups],
    });

    const selectedCreatives = processing.creatives.filter((c) => c.isSelected && c.group !== creativeGroups.COMMON);
    const failedCreatives = selectedCreatives.filter((c) => c.isFailed);

    if (failedCreatives.length > 0 && !this.hitProcessingFailedOnResultPageIsTriggered) {
      this.hitProcessingFailedOnResultPageIsTriggered = true;
      hitEvent(hits.PROCESSING_FAILED_ON_RESULT_PAGE);
    }

    if (firstTabCreatives.first().isFailed) {
      if (!this.firstTabCreativeCommitFailed) {
        this.firstTabCreativeCommitFailed = true;
        window.processingManager.commitToApi(processing.toJSON());
      }
    }
  });

  handleTabClick = (tab) => {
    if (tab === this.state.tab) {
      return;
    }

    if (tab === TAB_PRO_PROMOTION) {
      webviewShowBanner("postdownload");
      return;
    }

    if (tab !== TAB_PRO_PROMOTION && tab !== TAB_PHOTOLAB_PROMOTION) {
      hitFirstTabClick(window.processingManager.processing, tab);
    }

    window.processingManager.processing.setExtra(Processing.SELECTED_GROUP, tab);
    window.processingManager.update();

    this.setState({tab});
  }

  handleFileSelected = (file) => {
    hitEvent(hits.PHOTO_SELECT);
    logEvent(userEvents.PHOTO_SELECT, {page: "result"});

    this.context.showLoader(true, null, () => this.props.history.push(routes.UPLOAD, {file}));
  }

  handleDownload = (creative) => {
    const fileUrl = resolveCreativeImageFile(creative, true);
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/')+1);

    if (window.clientConfig.isWebview) {
      const providers = [];

      if (window.clientConfig.isWebviewIOS) {
        providers.push(0,1,2,3,5,6,11);
      } else if (window.clientConfig.isWebviewAndroid) {
        providers.push(0);
        this.context.installedApps.instagram && providers.push(1);
        this.context.installedApps.facebook && providers.push(2);
        this.context.installedApps.whatsapp && providers.push(3);
        this.context.installedApps.facebookm && providers.push(5);
        this.context.installedApps.telegram && providers.push(6);
        this.context.installedApps.snapchat && providers.push(11);
      }

      webviewUtils.webviewShare({
        providers: "[" + providers.join(",") + "]",
        imageUrl: encodeURIComponent(fileUrl),
      });

      if (this.isShowRateAppView()) {
        setTimeout(() => {
          this.context.pushModal(<RateAppModal key="ResultPage_RateAppModal" />);
        }, 1000);
      }

      return;
    }

    setTimeout(() => {
      // const url = new URL(window.appConfig.paths.api);
      // url.pathname = "/download";
      // url.searchParams.append("content_url", fileUrl);
      // saveAs(url.toString(), fileName);

      saveAs(transformToDownloadUrl(fileUrl), fileName);

      this.context.pushModal(<DownloadModal key="ResultPage_DownloadModal" />);
    }, 100);
  }

  handleDownloadClick = (creative) => {
    if (creative.getExtra(Creative.EXTRA_WM_ON_DEMAND, false)) {
      const processing = window.processingManager.processing;
      creative.markAsPending();
      this.handleProcessingChanged();

      const stepIndex = creative.getExtra(Creative.EXTRA_COMBO_STEPS).length;

      watermarkStep(processing, creative, stepIndex, frontendWatermarkStep)
        .then(() => {
          creative.setExtra(Creative.EXTRA_WM_ON_DEMAND, false);

          creative.markAsProcessed(
            creative.getFile("raw_watermark") || creative.getFile("raw")
          );
          this.handleProcessingChanged();
          this.handleDownload(creative);
        })
        .catch((error) => {
          creative.markAsProcessed(
            creative.getFile("raw_watermark") || creative.getFile("raw")
          );
          this.handleProcessingChanged();
          this.handleDownload(creative);
        });

      return;
    }

    this.handleDownload(creative);
  }

  isShowRateAppView = () => {
    // eslint-disable-next-line no-unreachable
    if (window.clientConfig.isWeb) {
      return false;
    }

    // показываем только на npp
    if (window.clientConfig.webviewParams.app_id !== "33") {
      return false;
    }

    const isShowDialog = (Math.random() <= window.appConfig.webview.rateAppDialogPercent)
      && (clientStorage.getRateAppRequestLastShow() <= Date.now() - 86400000)
      && !clientStorage.getRateAppRequestIsRate()
      && !clientStorage.getRateAppRequestIsSkip();

    if (isShowDialog) {
      hitEvent(hits.RATEAPP_DIALOG_SHOW);
      logEvent(userEvents.RATEAPP_DIALOG_SHOW);

      clientStorage.setRateAppRequestLastShow(Date.now());
    }

    return isShowDialog;
  };

  handleChangeMask = () => {
    const processing = window.processingManager.processing;

    this.context.showLoader(false, processing.file.url);

    this.props.history.replace(routes.MASK_EDITOR, {
      file: processing.file,
      mask: processing.maskFile,
      backTo: "result",
    });
  };

  render() {
    if (!this.context.loader.isHidden || !this.state.isReady) {
      return <React.Fragment />;
    }

    return <main className="result-page">
      <div className="tab-container">
        {this.state.tabs.map((tab, index) => <TabView
          key={index}
          group={tab}
          isActive={this.state.tab === tab}
          onClick={() => this.handleTabClick(tab)}
        />)}
        {false &&
        <TabView
          key={TAB_PHOTOLAB_PROMOTION}
          group={TAB_PHOTOLAB_PROMOTION}
          isActive={this.state.tab === TAB_PHOTOLAB_PROMOTION}
          onClick={() => this.handleTabClick(TAB_PHOTOLAB_PROMOTION)}
        />
        }
        <TabView
          hidden={window.clientConfig.isPro || window.clientConfig.isWeb}
          key={TAB_PRO_PROMOTION}
          group={TAB_PRO_PROMOTION}
          isActive={this.state.tab === TAB_PRO_PROMOTION}
          onClick={() => this.handleTabClick(TAB_PRO_PROMOTION)}
        />
      </div>

      {this.renderTabContentView()}
    </main>;
  }

  renderTabContentView = () => {
    if (this.state.tab === TAB_PHOTOLAB_PROMOTION) {
      return <PhotolabAdTabContent />;
    } else {
      return <TabContentView
        key={this.state.tab}
        group={this.state.tab}
        onProcessingChanged={this.handleProcessingChanged}
        onFileSelected={this.handleFileSelected}
        onDownloadClick={this.handleDownloadClick}
        onChangeMaskClick={this.handleChangeMask}
      />;
    }
  };
}

ResultPage.contextType = AppContext;

function hitFirstTabClick(processing, tab, hit = true) {
  const cacheKey = `tab_first_click:${processing.id}_${tab}`;

  if (!window.appGlobalCache[cacheKey]) {
    window.appGlobalCache[cacheKey] = true;
    if (hit) {
      hitEvent(hits.FIRST_CLICK_TAB);
    }
  }
}
